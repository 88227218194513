<template>
  <div class="bgr">
    <div class="about">
      <!-- <h1 class="pt-5 pb-5">We help you save money</h1>
      <div class="center">
        <b-button-toolbar style="margin-left: -160px">
          <b-button-group size="sm" class="mr-1">
            <b-button @click="saveClick">Save</b-button>
            <b-button @click="value = 200">Cancel</b-button>
          </b-button-group>
          <b-input-group size="sm" prepend="€" append=".00">
            <b-form-input v-model="value" :value="value" class="text-right"></b-form-input>
          </b-input-group>
        </b-button-toolbar>
      </div>
      <h1 class="pt-3">{{ value }} €</h1> -->

      <b-container class="pt-4 mt-4 maxw">
        <div class="p-4 mb-4 usp-bgr mtk-shadow">
          <img
            class="pt-3 about-img"
            :src="cdnurl + icons_path + 'flag.svg'"
            alt="alt text"
            title="Logo Title Text 1"
          />
          <h3 class="text-dark pt-3">{{ $t('aboutSection.ourVisionTitle') }}</h3>
          <p class="text-dark">{{ $t('aboutSection.ourVisionDesc') }}</p>
        </div>
      </b-container>

      <b-container class="pt-4 mt-4 maxw">
        <div class="p-4 mb-4 usp-bgr mtk-shadow">
          <img
            class="pt-3 about-img"
            :src="cdnurl + icons_path + 'team.svg'"
            alt="alt text"
            title="Logo Title Text 1"
          />
          <h3 class="text-dark pt-3">{{ $t('aboutSection.aboutUsTitle') }}</h3>
          <p class="text-dark">{{ $t('aboutSection.aboutUsDesc') }}</p>
        </div>
      </b-container>
      <b-container class="pt-4 mt-4 maxw">
        <div class="p-4 mb-4 usp-bgr mtk-shadow">
          <img
            class="pt-3 about-img"
            :src="cdnurl + icons_path + 'foodwaste.png'"
            alt="alt text"
            title="Logo Title Text 1"
          />
          <h3 class="text-dark pt-3">{{ $t('aboutSection.foodWasteTitle') }}</h3>
          <p class="text-dark">{{ $t('aboutSection.foodWasteDesc') }}</p>
        </div>
      </b-container>
      <b-container class="pt-4 mt-4 maxw">
        <div class="p-4 mb-4 usp-bgr mtk-shadow">
          <img
            class="pt-3 about-img"
            :src="cdnurl + icons_path + 'food_darkblue.png'"
            alt="alt text"
            title="Logo Title Text 1"
          />
          <h3 class="text-dark pt-3">{{ $t('aboutSection.ourMissionTitle') }}</h3>
          <p class="text-dark">{{ $t('aboutSection.ourMissionDesc') }}</p>
        </div>
        <the-rating />
      </b-container>
    </div>
  </div>
</template>

<script>
import TheRating from '../components/TheRating.vue';
export default {
  components: { TheRating },
  created() {
    fetch(`${this.cdnurl}cloud-mitakus-eu-data/about-page.json`)
      .then((response) => response.json())
      .then((data) => {
        this.texts = data.texts;
      });
  },
  data() {
    return {
      value: 200,
      // texts: [],
      cdnurl: 'https://cdn.jsdelivr.net/gh/romanhubxz/romanhubxz.github.io/',
      icons_path: 'generic-images/cloud-eu-icons/',
    };
  },
  methods: {
    saveClick() {
      this.value = Math.floor(this.value / 2);
    },
  },
};
</script>

<style>
.about {
  height: 100vh;
}
.about-img {
  height: 200px;
}
</style>
