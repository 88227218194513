<template>
  <div>
    <b-form-rating class="usp-bgr" v-model="value"></b-form-rating>
    <p class="mt-2 pb-2 text-white">
      {{ $t('theRaiting.raiting') }} {{ value }} {{ $t('theRaiting.stars') }}
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: null,
    };
  },
};
</script>
